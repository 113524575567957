import { MdElectricCar, MdElectricRickshaw, MdOutlineElectricBike } from "react-icons/md";
import { BsNewspaper } from "react-icons/bs";
import { FaTruckMoving } from "react-icons/fa";

export const CATEGORIES = [
  {
    label: "EV's NEWS",
    color: "bg-[#e11d48]",
    text: "text-[#fff]",
    icon: <BsNewspaper />,
  },
  {
    label: "BIKES",
    color: "bg-[#50C878]",
    icon: <MdOutlineElectricBike />,
  },
  {
    label: "CARS",
    color: "bg-[#008000]",
    icon: <MdElectricCar />,
  },
  {
    label: "BUS & TRUCKS",
    color: "bg-[#4CBB17]",
    icon: <FaTruckMoving />,
  },
  {
    label: "OTHERS",
    color: "bg-[#9333ea]",
    icon: <MdElectricRickshaw />,
  },
];
