// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: "evsdrive-e9ce9.firebaseapp.com",
  projectId: "evsdrive-e9ce9",
  storageBucket: "evsdrive-e9ce9.appspot.com",
  messagingSenderId: "707628752283",
  appId: "1:707628752283:web:90a443a454afd56055d53e",
  measurementId: "G-QHHDMXNX80"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);