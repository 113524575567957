import React from 'react';
import { Link } from 'react-router-dom';
import Logo2 from '../assets/logo2.png'; // Correct import

const Logo = ({ type }) => {
  return (
    <Link 
    to="/"
    className="flex items-center">
      <img
        src={Logo2} // Use the imported image directly
        alt="Ev"
        className="inline-block mr-2 h-10" // Set the image height to 40px
      />
      <Link
        to="/"
        className={`text-3xl dark:text-white ${
          type && 'text-white text-4xl font-semibold'
        }`}
      >
        EVs
        <span
          className={`text-3xl text-green-500 ${type && 'text-5xl font-bold'}`}
        >
          Drive
        </span>
      </Link>
    </Link>
  );
};

export default Logo;
